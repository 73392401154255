import {Injectable} from "@angular/core";

declare let window: any;

@Injectable({
    providedIn: 'root',
})
export class GoogleTagManagerService {
    constructor() {
    }

    trackEvent(data: any) {
        if (window && window.dataLayer) {
            window.dataLayer.push(data);
        }
    }
}
